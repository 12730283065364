"use client"
import React, { useState, useEffect } from 'react';

const CarouselComponent = ({ cardData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const updateView = () => {
            setIsMobile(window.innerWidth < 768);
        };

        updateView();
        window.addEventListener('resize', updateView);
        return () => window.removeEventListener('resize', updateView);
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? cardData.length - 1 : prevIndex - 1
        );
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    if (isMobile) {
        return (
            <div id="default-carousel" className="relative w-full p-4" data-carousel="slide">
                <div className="relative h-auto overflow-hidden rounded-lg">
                    {cardData.map((card, index) => (
                        <div
                            key={index}
                            className={`duration-700 ease-in-out ${index === currentIndex ? 'block' : 'hidden'}`}
                            data-carousel-item
                        >
                            <div className="p-6 md:p-8">
                                <h3 className="text-lg md:text-xl font-bold mb-4 text-[#94255a]">{card.title}</h3>
                                <p className="text-base md:text-lg font-semibold leading-relaxed mb-4">Avantages :</p>
                                <ul className="list-disc pl-5 space-y-2 text-left">
                                    {card.benefits.map((benefit, idx) => (
                                        <li key={idx} className="text-base md:text-base leading-relaxed">{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="absolute flex -translate-x-1/2 -bottom-5 left-1/2 space-x-3">
                    {cardData.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`w-3 h-3 rounded-full ${currentIndex === index ? 'bg-[#94255a]' : 'bg-gray-300'}`}
                            aria-current={currentIndex === index}
                            aria-label={`Slide ${index + 1}`}
                            onClick={() => goToSlide(index)}
                        ></button>
                    ))}
                </div>
                <button
                    type="button"
                    className="absolute top-0 -left-4 z-30 flex items-center justify-center h-full px-4 cursor-pointer"
                    onClick={handlePrev}
                >
                    <span className="inline-flex items-center justify-center w-8 h-8">
                        <svg
                            className="w-5 h-5 text-[#94255a]"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 1 1 5l4 4"
                            />
                        </svg>
                        <span className="sr-only">Previous</span>
                    </span>
                </button>
                <button
                    type="button"
                    className="absolute top-0 -right-4 z-30 flex items-center justify-center h-full px-4 cursor-pointer"
                    onClick={handleNext}
                >
                    <span className="inline-flex items-center justify-center w-8 h-8">
                        <svg
                            className="w-5 h-5 text-[#94255a]"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 9 4-4-4-4"
                            />
                        </svg>
                        <span className="sr-only">Next</span>
                    </span>
                </button>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
            {cardData.map((card, index) => (
                <div key={index} className="p-4">
                    <div className="p-6">
                        <h3 className="text-lg md:text-xl font-bold mb-4 text-[#94255a]">{card.title}</h3>
                        <p className="text-base md:text-lg font-semibold leading-relaxed mb-4">Avantages :</p>
                        <ul className="list-disc space-y-2">
                            {card.benefits.map((benefit, idx) => (
                                <li key={idx} className="text-base md:text-lg lg:text-left">{benefit}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CarouselComponent;








