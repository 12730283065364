import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/carouselComponent.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/footer.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/GoogleReviews.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/latestArticles.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/scrollToTopButton.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
