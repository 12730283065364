"use client";

import { cn } from "@/lib/utils";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import { useState } from "react";

export const CardContainer = ({ children, className, ...props }) => {
    const mouseX = useMotionValue(0);
    const mouseY = useMotionValue(0);

    // Augmentation de l'amplitude de rotation pour un effet plus prononcé
    const rotateX = useSpring(useTransform(mouseY, [-300, 300], [20, -20]), {
        damping: 15,
        stiffness: 150
    });
    const rotateY = useSpring(useTransform(mouseX, [-300, 300], [-20, 20]), {
        damping: 15,
        stiffness: 150
    });

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;

        mouseX.set(x - width / 2);
        mouseY.set(y - height / 2);
    };

    const handleMouseLeave = () => {
        mouseX.set(0);
        mouseY.set(0);
    };

    return (
        <motion.div
            className={cn("relative group/card cursor-pointer", className)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{
                rotateX,
                rotateY,
                transformStyle: "preserve-3d",
                perspective: "1200px"
            }}
            {...props}
        >
            <div
                className="relative"
                style={{
                    transformStyle: "preserve-3d",
                    transition: "transform 0.3s ease"
                }}
            >
                {children}
            </div>
        </motion.div>
    );
};

export const CardBody = ({ children, className, ...props }) => {
    return (
        <div
            className={cn(
                "relative w-full h-full p-4 overflow-hidden bg-transparent",
                className
            )}
            style={{
                transformStyle: "preserve-3d",
                transform: "translateZ(50px)"
            }}
            {...props}
        >
            {children}
        </div>
    );
};

export const CardItem = ({
    children,
    className,
    translateZ = 0,
    ...props
}) => {
    return (
        <motion.div
            className={cn("w-fit", className)}
            style={{
                transform: `perspective(1000px) translateZ(${translateZ}px)`,
                transformStyle: "preserve-3d",
                transition: "transform 0.3s ease"
            }}
            whileHover={{ scale: 1.05 }}
            {...props}
        >
            {children}
        </motion.div>
    );
}; 