'use client';

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GoogleReviews = () => {
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    // Fonction pour récupérer les avis
    const fetchReviews = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/reviews/google-reviews`);
            setReviews(response.data.reviews || []);
            setError(null);
        } catch (err) {
            setError(err.message || 'Une erreur inconnue est survenue.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    if (error) {
        return (
            <div className="bg-red-100 text-red-600 rounded-lg p-4 mx-4 lg:mx-20 mt-10 text-center">
                <p>Erreur lors de la récupération des avis : {error}</p>
                <button
                    onClick={fetchReviews}
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                >
                    Réessayer
                </button>
            </div>
        );
    }

    return (
        <div className="bg-white shadow-lg rounded-2xl p-8 mx-4 lg:mx-20 mt-20 mb-10">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                Avis <span className="text-[#4285F4]">G</span><span className="text-[#EA4335]">o</span><span className="text-[#FBBC05]">o</span><span className="text-[#4285F4]">g</span><span className="text-[#34A853]">l</span><span className="text-[#EA4335]">e</span>
            </h2>

            {/* Spinner dégradé pendant le chargement */}
            {loading ? (
                <div className="flex justify-center items-center mt-10">
                    <div className="relative w-16 h-16 animate-spin">
                        <div
                            className="absolute inset-0 rounded-full border-t-4"
                            style={{
                                borderTopColor: '#4285F4', // Bleu
                                borderRightColor: '#EA4335', // Rouge
                                borderBottomColor: '#FBBC05', // Jaune
                                borderLeftColor: '#34A853', // Vert
                                borderStyle: 'solid',
                                borderWidth: '4px',
                            }}
                        ></div>
                    </div>
                </div>
            ) : reviews.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 items-stretch">
                    {reviews.map((review, index) => (
                        <div
                            key={index}
                            className="bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-200 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
                        >
                            <div className="flex items-center gap-4 mb-4">
                                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                                    <span className="text-gray-600 font-bold text-xl">
                                        {review.authorName ? review.authorName[0] : '?'}
                                    </span>
                                </div>
                                <div>
                                    <p className="text-lg font-semibold text-gray-800">{review.authorName}</p>
                                    <p className="text-sm text-gray-500">{review.relativeTime}</p>
                                </div>
                            </div>
                            <p className="text-yellow-500 text-lg font-semibold mb-4">
                                {'★'.repeat(review.rating)}{' '}
                                {'☆'.repeat(5 - review.rating)}
                            </p>
                            <p className="text-gray-700 text-sm leading-relaxed">
                                {isExpanded ? review.text : `${review.text.substring(0, 200)}...`}
                            </p>
                            {review.text.length > 200 && (
                                <button
                                    onClick={() => setIsExpanded(!isExpanded)} // Inverse l'état pour étendre ou réduire le texte
                                    className="text-blue-500 text-sm mt-2"
                                >
                                    {isExpanded ? 'Lire moins' : 'Lire la suite'}
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-center text-gray-500">Aucun avis pour l&apos;instant.</p>
            )}
        </div>
    );
};

export default GoogleReviews;








